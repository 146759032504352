// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item {
  overflow-x: auto;
  position: relative;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

.custom-table th {
  background-color: #2c2b2b;
  position: sticky;
  top: 0;
  z-index: 1;
}

.custom-table th:first-child,
.custom-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #2c2b2b;
}

.custom-table th:first-child {
  z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NEW/TableStyles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA;;EAEE,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".grid-item {\n  overflow-x: auto;\n  position: relative;\n}\n\n.custom-table {\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.custom-table th,\n.custom-table td {\n  /* border: 1px solid #ddd; */\n  padding: 8px;\n}\n\n.custom-table th {\n  background-color: #2c2b2b;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\n.custom-table th:first-child,\n.custom-table td:first-child {\n  position: sticky;\n  left: 0;\n  z-index: 2;\n  background-color: #2c2b2b;\n}\n\n.custom-table th:first-child {\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
