// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-toggle {
    padding: 10px 20px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #4a5568;
    border: 1px solid #ffb300;
    border-radius: 9999px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    display: grid;
    place-items: center;
    text-align: center;
}

.button-toggle:hover {
    background-color: #ffb300;
    box-shadow: 0px 15px 20px rgba(214, 211, 43, 0.4);
    color: #4a5568;
    transform: translateY(-7px);
}

.button-toggle.activebutton {
    background-color: #ffb300;
    color: #4a5568;
    transform: translateY(-1px);
    box-shadow: 0px 15px 20px rgba(214, 211, 43, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/ToggleButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,qBAAqB;IACrB,2CAA2C;IAC3C,4BAA4B;IAC5B,eAAe;IACf,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,iDAAiD;IACjD,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,2BAA2B;IAC3B,iDAAiD;AACrD","sourcesContent":[".button-toggle {\n    padding: 10px 20px;\n    font-size: 12px;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    font-weight: 500;\n    color: #fff;\n    background-color: #4a5568;\n    border: 1px solid #ffb300;\n    border-radius: 9999px;\n    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);\n    transition: all 0.3s ease 0s;\n    cursor: pointer;\n    outline: none;\n    display: grid;\n    place-items: center;\n    text-align: center;\n}\n\n.button-toggle:hover {\n    background-color: #ffb300;\n    box-shadow: 0px 15px 20px rgba(214, 211, 43, 0.4);\n    color: #4a5568;\n    transform: translateY(-7px);\n}\n\n.button-toggle.activebutton {\n    background-color: #ffb300;\n    color: #4a5568;\n    transform: translateY(-1px);\n    box-shadow: 0px 15px 20px rgba(214, 211, 43, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
