// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fontSizeImportant {
  font-size: 1rem !important;
}

.fontSizeImportantMobile {
  font-size: 0.6rem !important;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResultTabs.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;AACnB","sourcesContent":[".fontSizeImportant {\n  font-size: 1rem !important;\n}\n\n.fontSizeImportantMobile {\n  font-size: 0.6rem !important;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
