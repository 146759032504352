// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.footer-text {
  color: #FFF;
  font-size: 12px;
  opacity: 0.5;
}
  
  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-item {
    margin: 0;
  }
  
  /* Responsive styles for footer */
  @media (max-width: 767px) {
    .footer-nav.flex {
      flex-wrap: wrap;
    }
  
    .footer-nav.gap-20 {
      gap: 10px; /* Adjust this value as needed for smaller screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CSS/Footer.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,eAAe;EACf,YAAY;AACd;;EAEE;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,SAAS;EACX;;EAEA,iCAAiC;EACjC;IACE;MACE,eAAe;IACjB;;IAEA;MACE,SAAS,EAAE,oDAAoD;IACjE;EACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');\n\n.footer-container {\n  font-family: 'Inter', sans-serif;\n  font-weight: 400;\n}\n.footer-text {\n  color: #FFF;\n  font-size: 12px;\n  opacity: 0.5;\n}\n  \n  .footer-nav {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .footer-item {\n    margin: 0;\n  }\n  \n  /* Responsive styles for footer */\n  @media (max-width: 767px) {\n    .footer-nav.flex {\n      flex-wrap: wrap;\n    }\n  \n    .footer-nav.gap-20 {\n      gap: 10px; /* Adjust this value as needed for smaller screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
