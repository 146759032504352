import api from "./api";

export const addToWaitlist = async (data) => {
  try {
    const response = await api.post("api/waitlist", JSON.stringify(data));
    return response;
  } catch (error) {
    console.error("An error occurred during waitlist addition:", error);
    return { error: "An error occurred during waitlist addition." };
  }
};

export const verifyEmail = async (email, code) => {
  try {
    const response = await api.put(`api/waitlist/verify/${email}/${code}`);
    return response;
  } catch (error) {
    console.error("An error occurred during waitlist verification:", error);
    return { error: "An error occurred during waitlist verification." };
  }
};

export const getAllWaitlist = async () => {
  try {
    const response = await api.get("api/waitlist");
    return response;
  } catch (error) {
    console.error("An error occurred during waitlist retrieval:", error);
    return { error: "An error occurred during waitlist retrieval." };
  }
};
