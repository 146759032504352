import React from 'react'
import MainNavBar from '../components/MainNavBar'

const Records = () => {
  return (
    <div className=' w-full'>
    <MainNavBar/>
  </div>
  )
}

export default Records