import React from 'react'

const Teams = () => {
  return (
    <div className=' w-full'>
      Teams
  </div>
  )
}

export default Teams