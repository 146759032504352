import React from 'react'
import MainNavBar from '../components/MainNavBar'

const Pools = () => {
  return (
    <div className=' w-full'>
    <MainNavBar/>
  </div>
  )
}

export default Pools