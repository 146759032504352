import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';

const RestrictedPage = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      height="25vh"
      textAlign="center"
    >
      {/* Red colored heading */}
      <Typography variant="h4" gutterBottom sx={{ color: 'red' }}>
        Access Restricted!
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ color: 'white' }}>
        You do not have permission to access this page. Please contact the administrator if you believe this is an error.
      </Typography>

      {/* Gold button with white bold text */}
      <Button 
        variant="contained" 
        onClick={goToHome}
        sx={{
          backgroundColor: '#b8860b',
          marginTop : '30px',
          color: 'black',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'darkgoldenrod'
          }
        }}
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default RestrictedPage;
